import React from "react";

const LinkedinLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M7.34961 10V17"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3496 13V17M11.3496 13C11.3496 11.3431 12.6927 10 14.3496 10C16.0065 10 17.3496 11.3431 17.3496 13V17M11.3496 13V10"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.35762 7H7.34863"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.84961 12C2.84961 7.52166 2.84961 5.28249 4.24085 3.89124C5.6321 2.5 7.87127 2.5 12.3496 2.5C16.8279 2.5 19.0671 2.5 20.4584 3.89124C21.8496 5.28249 21.8496 7.52166 21.8496 12C21.8496 16.4783 21.8496 18.7175 20.4584 20.1088C19.0671 21.5 16.8279 21.5 12.3496 21.5C7.87127 21.5 5.6321 21.5 4.24085 20.1088C2.84961 18.7175 2.84961 16.4783 2.84961 12Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LinkedinLogo;
