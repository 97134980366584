import React, { ReactNode } from "react";
import LocalizedClientLink from "../localized-client-link";

interface CtaButtonProps {
  href?: string;
  children: ReactNode;
  onClick?: (e) => void;
  bgColor?: string;
  textColor?: string;
  px?: number | string;
  py?: number | string;
  h?: number | string;
  customClass?: string;
  external?: boolean;
}
const CtaButton = ({
  href,
  children,
  onClick,
  bgColor = "customGreen",
  textColor = "white",
  px = "[60px]",
  py = 5,
  h = 14,
  customClass = "",
  external = false,
}: CtaButtonProps) => {
  return (
    <>
      {href ? (
        external ? (
          <>
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className={`cta_btn flex items-center justify-center h-${h} py-${py} px-${px} bg-${bgColor} text-${textColor} text-base	rounded-lg w-full sm:w-auto text-center font-sans text-base font-normal ${customClass}`}
            >
              {children}
            </a>
          </>
        ) : onClick ? (
          <LocalizedClientLink
            className={`cta_btn flex items-center justify-center h-${h} py-${py} px-${px} bg-${bgColor} text-${textColor} text-base	rounded-lg w-full sm:w-auto text-center font-sans text-base font-normal ${customClass}`}
            // className="flex gap-x-1 items-center group"
            href={href}
            onClick={onClick}
          >
            {children}
          </LocalizedClientLink>
        ) : (
          <LocalizedClientLink
            className={`cta_btn flex items-center justify-center h-${h} py-${py} px-${px} bg-${bgColor} text-${textColor} text-base	rounded-lg w-full sm:w-auto text-center font-sans text-base font-normal ${customClass}`}
            // className="flex gap-x-1 items-center group"
            href={href}
          >
            {children}
          </LocalizedClientLink>
        )
      ) : (
        <>
          <button
            className={`cta_btn flex items-center justify-center h-${h} py-${py} px-${px} bg-${bgColor} text-${textColor} text-base	rounded-lg w-full sm:w-auto text-center font-sans text-base font-normal ${customClass}`}
            style={{ borderRadius: "8px" }}
            onClick={onClick}
          >
            {children}
          </button>
        </>
      )}
    </>
  );
};

export default CtaButton;
