import React, { ReactNode } from "react";
import LocalizedClientLink from "@/modules/common/components/localized-client-link";

import styles from "./navlinkstyle.module.scss";

interface NavLinkProps {
  href: string;
  children?: ReactNode;
}

const NavLink = ({ href, children }: NavLinkProps) => {
  return (
    <li className={`${styles.item} font-sans`}>
      <LocalizedClientLink href={href}>{children}</LocalizedClientLink>
    </li>
  );
};
export default NavLink;
