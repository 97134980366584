import React from "react";

const IgLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M2.84961 12C2.84961 7.52166 2.84961 5.28249 4.24085 3.89124C5.6321 2.5 7.87127 2.5 12.3496 2.5C16.8279 2.5 19.0671 2.5 20.4584 3.89124C21.8496 5.28249 21.8496 7.52166 21.8496 12C21.8496 16.4783 21.8496 18.7175 20.4584 20.1088C19.0671 21.5 16.8279 21.5 12.3496 21.5C7.87127 21.5 5.6321 21.5 4.24085 20.1088C2.84961 18.7175 2.84961 16.4783 2.84961 12Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M16.8496 12C16.8496 14.4853 14.8349 16.5 12.3496 16.5C9.86433 16.5 7.84961 14.4853 7.84961 12C7.84961 9.51472 9.86433 7.5 12.3496 7.5C14.8349 7.5 16.8496 9.51472 16.8496 12Z"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M17.8576 6.5H17.8486"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IgLogo;
