import React from "react";

interface GeneseqLogoProps {
  fill: string;
}
const GeneseqLogo = ( GeneseqLogoProps) => {
  return (
    <svg
    width={209}
    height={30}
    viewBox="0 0 209 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M74.7141 23.2736C73.208 23.2736 71.7995 22.9614 70.4885 22.3368C69.1768 21.7123 68.032 20.8508 67.0542 19.7515C66.0777 18.6529 65.3153 17.4025 64.7674 16.0015C64.2189 14.6005 63.9453 13.1137 63.9453 11.5398C63.9453 9.96648 64.2189 8.49025 64.7674 7.11178C65.3153 5.73331 66.0777 4.50535 67.0542 3.42648C68.032 2.34832 69.1824 1.50788 70.5053 0.905852C71.8276 0.302419 73.2733 0 74.8431 0C77.0127 0 78.8442 0.458552 80.3369 1.37566C81.831 2.29135 82.9555 3.50244 83.7095 5.01032L80.9346 7.01332C80.3341 5.76425 79.4797 4.82746 78.3729 4.20293C77.2653 3.57839 76.0559 3.26613 74.7449 3.26613C73.671 3.26613 72.6925 3.49259 71.81 3.94411C70.929 4.39633 70.1714 5.01594 69.5373 5.80364C68.9032 6.58993 68.4248 7.48453 68.1035 8.48744C67.7816 9.48894 67.6209 10.5397 67.6209 11.6382C67.6209 12.8015 67.8033 13.8888 68.1681 14.9015C68.5328 15.9143 69.0477 16.8033 69.7141 17.5685C70.3819 18.3336 71.1661 18.9322 72.0682 19.3633C72.9716 19.7951 73.9495 20.0103 75.0031 20.0103C76.2502 20.0103 77.4385 19.6924 78.5665 19.0566C79.6944 18.4209 80.7529 17.4672 81.7426 16.1956V19.8809C79.7428 22.1427 77.3999 23.2736 74.7141 23.2736ZM81.3863 14.1898H76.5827V11.5088H84.4166V23.1133H81.3863V14.1898Z"
      fill="black"
    />
    <path
      d="M97.7657 23.4368C96.4539 23.4368 95.2664 23.2047 94.2023 22.7419C93.1374 22.2791 92.2185 21.6434 91.4441 20.8346C90.6711 20.0265 90.0756 19.1002 89.6568 18.0551C89.2374 17.0086 89.0283 15.8932 89.0283 14.7074C89.0283 13.1137 89.3931 11.6544 90.1226 10.3301C90.8535 9.00433 91.8748 7.93742 93.1865 7.12863C94.4976 6.32054 96.0345 5.91614 97.7965 5.91614C99.56 5.91614 101.081 6.32054 102.359 7.12863C103.638 7.93742 104.632 8.99871 105.341 10.3132C106.05 11.6283 106.405 13.0392 106.405 14.547C106.405 14.8059 106.394 15.0492 106.371 15.2757C106.35 15.5007 106.33 15.6892 106.309 15.8411H92.7993C92.8646 16.8314 93.1389 17.6992 93.6215 18.4433C94.1055 19.186 94.7241 19.7676 95.4761 20.1875C96.2281 20.6081 97.034 20.8177 97.8947 20.8177C98.8389 20.8177 99.736 20.5814 100.586 20.1087C101.434 19.6347 102.009 19.0088 102.311 18.2323L105.341 19.1044C104.977 19.9245 104.422 20.6629 103.68 21.3212C102.94 21.9781 102.064 22.4943 101.054 22.8713C100.044 23.2483 98.9476 23.4368 97.7657 23.4368ZM92.7039 13.4808H102.892C102.826 12.5117 102.551 11.6551 102.067 10.9124C101.584 10.1683 100.971 9.58596 100.229 9.16539C99.4884 8.74552 98.667 8.53523 97.7657 8.53523C96.8846 8.53523 96.0723 8.74552 95.3302 9.16539C94.5895 9.58596 93.982 10.1683 93.5092 10.9124C93.0357 11.6551 92.7671 12.5117 92.7039 13.4808Z"
      fill="black"
    />
    <path
      d="M126.46 23.113H122.914V13.6409C122.914 12.0677 122.661 10.915 122.156 10.1835C121.651 9.45068 120.903 9.08356 119.914 9.08356C119.225 9.08356 118.543 9.25657 117.866 9.60119C117.188 9.94651 116.582 10.4156 116.045 11.0078C115.508 11.6007 115.121 12.2843 114.883 13.0586V23.113H111.337V6.20565H114.564V9.60119C114.991 8.84725 115.555 8.19599 116.253 7.64601C116.952 7.09674 117.743 6.67124 118.624 6.36882C119.506 6.0671 120.442 5.91589 121.429 5.91589C122.44 5.91589 123.271 6.09875 123.927 6.46447C124.583 6.83018 125.099 7.33093 125.475 7.96672C125.851 8.6025 126.11 9.33605 126.25 10.1666C126.39 10.9958 126.46 11.8736 126.46 12.7998V23.113Z"
      fill="black"
    />
    <path
      d="M140.26 23.4368C138.948 23.4368 137.761 23.2047 136.696 22.7419C135.632 22.2791 134.713 21.6434 133.938 20.8346C133.165 20.0265 132.57 19.1002 132.151 18.0551C131.731 17.0086 131.522 15.8932 131.522 14.7074C131.522 13.1137 131.887 11.6544 132.617 10.3301C133.348 9.00433 134.369 7.93742 135.681 7.12863C136.992 6.32054 138.529 5.91614 140.291 5.91614C142.054 5.91614 143.575 6.32054 144.853 7.12863C146.132 7.93742 147.126 8.99871 147.836 10.3132C148.544 11.6283 148.899 13.0392 148.899 14.547C148.899 14.8059 148.888 15.0492 148.865 15.2757C148.844 15.5007 148.824 15.6892 148.804 15.8411H135.293C135.359 16.8314 135.633 17.6992 136.116 18.4433C136.6 19.186 137.218 19.7676 137.97 20.1875C138.722 20.6081 139.528 20.8177 140.389 20.8177C141.333 20.8177 142.23 20.5814 143.08 20.1087C143.928 19.6347 144.504 19.0088 144.805 18.2323L147.836 19.1044C147.471 19.9245 146.917 20.6629 146.174 21.3212C145.434 21.9781 144.558 22.4943 143.548 22.8713C142.538 23.2483 141.442 23.4368 140.26 23.4368ZM135.198 13.4808H145.386C145.32 12.5117 145.045 11.6551 144.561 10.9124C144.079 10.1683 143.465 9.58596 142.723 9.16539C141.983 8.74552 141.161 8.53523 140.26 8.53523C139.379 8.53523 138.566 8.74552 137.824 9.16539C137.084 9.58596 136.476 10.1683 136.003 10.9124C135.53 11.6551 135.261 12.5117 135.198 13.4808Z"
      fill="black"
    />
    <path
      d="M159.83 23.4368C158.41 23.4368 157.066 23.2047 155.798 22.7419C154.529 22.2791 153.444 21.5843 152.543 20.6573L153.865 18.2633C154.831 19.1262 155.804 19.7515 156.783 20.1397C157.76 20.5279 158.733 20.722 159.701 20.722C160.688 20.722 161.494 20.5335 162.116 20.1566C162.741 19.7782 163.054 19.2226 163.054 18.4912C163.054 17.9531 162.892 17.5544 162.568 17.2955C162.246 17.0367 161.778 16.811 161.165 16.6176C160.554 16.4227 159.817 16.2061 158.957 15.9677C157.754 15.602 156.744 15.2208 155.927 14.8227C155.111 14.4233 154.499 13.9331 154.089 13.3514C153.681 12.7684 153.477 12.025 153.477 11.1206C153.477 10.022 153.756 9.0838 154.316 8.30736C154.875 7.53092 155.655 6.93874 156.653 6.52942C157.652 6.1208 158.807 5.91614 160.119 5.91614C161.343 5.91614 162.477 6.10462 163.519 6.48159C164.563 6.85856 165.482 7.44582 166.277 8.24266L164.762 10.6029C164.01 9.913 163.242 9.40662 162.456 9.0838C161.672 8.76169 160.829 8.59993 159.925 8.59993C159.409 8.59993 158.914 8.65901 158.441 8.77717C157.969 8.89532 157.577 9.11123 157.265 9.4242C156.954 9.73576 156.799 10.1718 156.799 10.7323C156.799 11.1846 156.927 11.551 157.184 11.8323C157.442 12.1122 157.829 12.3492 158.345 12.544C158.862 12.7375 159.496 12.952 160.248 13.1883C161.559 13.5765 162.676 13.9591 163.601 14.3361C164.525 14.713 165.239 15.1983 165.744 15.7905C166.249 16.3834 166.502 17.1971 166.502 18.2323C166.502 19.8696 165.894 21.1468 164.681 22.0639C163.467 22.9796 161.85 23.4368 159.83 23.4368Z"
      fill="black"
    />
    <path
      d="M179.335 23.4368C178.023 23.4368 176.836 23.2047 175.772 22.7419C174.707 22.2791 173.788 21.6434 173.013 20.8346C172.24 20.0265 171.645 19.1002 171.226 18.0551C170.807 17.0086 170.598 15.8932 170.598 14.7074C170.598 13.1137 170.962 11.6544 171.692 10.3301C172.423 9.00433 173.444 7.93742 174.756 7.12863C176.067 6.32054 177.604 5.91614 179.366 5.91614C181.129 5.91614 182.65 6.32054 183.928 7.12863C185.208 7.93742 186.202 8.99871 186.911 10.3132C187.619 11.6283 187.974 13.0392 187.974 14.547C187.974 14.8059 187.963 15.0492 187.94 15.2757C187.919 15.5007 187.899 15.6892 187.879 15.8411H174.369C174.434 16.8314 174.708 17.6992 175.191 18.4433C175.675 19.186 176.293 19.7676 177.045 20.1875C177.797 20.6081 178.603 20.8177 179.464 20.8177C180.408 20.8177 181.305 20.5814 182.155 20.1087C183.004 19.6347 183.579 19.0088 183.88 18.2323L186.911 19.1044C186.546 19.9245 185.992 20.6629 185.25 21.3212C184.509 21.9781 183.634 22.4943 182.623 22.8713C181.613 23.2483 180.517 23.4368 179.335 23.4368ZM174.273 13.4808H184.461C184.395 12.5117 184.12 11.6551 183.636 10.9124C183.154 10.1683 182.541 9.58596 181.798 9.16539C181.058 8.74552 180.236 8.53523 179.335 8.53523C178.454 8.53523 177.642 8.74552 176.9 9.16539C176.159 9.58596 175.551 10.1683 175.079 10.9124C174.605 11.6551 174.336 12.5117 174.273 13.4808Z"
      fill="black"
    />
    <path
      d="M199.324 23.4368C198.227 23.4368 197.222 23.2047 196.307 22.7419C195.394 22.2791 194.605 21.6433 193.939 20.8346C193.273 20.0265 192.762 19.089 192.407 18.0213C192.054 16.9544 191.877 15.8285 191.877 14.6427C191.877 13.4372 192.075 12.3063 192.472 11.25C192.87 10.1943 193.429 9.26807 194.15 8.47053C194.869 7.67158 195.713 7.04634 196.681 6.59412C197.647 6.1426 198.689 5.91614 199.806 5.91614C201.097 5.91614 202.263 6.2284 203.305 6.85293C204.349 7.47747 205.171 8.28626 205.771 9.27792V6.2059H208.9V30H205.353V19.9456C203.935 22.2735 201.925 23.4368 199.324 23.4368ZM200.614 20.3985C201.323 20.3985 201.973 20.2593 202.564 19.9793C203.155 19.698 203.682 19.3154 204.144 18.8316C204.606 18.3463 205.009 17.7907 205.353 17.1661V12.7044C205.245 12.1868 205.034 11.7078 204.722 11.2668C204.411 10.8245 204.03 10.4257 203.58 10.0712C203.129 9.71537 202.646 9.44038 202.129 9.24697C201.613 9.05216 201.108 8.9544 200.614 8.9544C199.862 8.9544 199.173 9.11686 198.549 9.44108C197.926 9.7639 197.383 10.1999 196.922 10.7492C196.46 11.2992 196.105 11.9188 195.858 12.6087C195.612 13.2973 195.488 14.0083 195.488 14.7412C195.488 15.775 195.708 16.7174 196.147 17.5684C196.589 18.4201 197.191 19.1044 197.954 19.6221C198.718 20.1397 199.604 20.3985 200.614 20.3985Z"
      fill="black"
    />
    <mask
      id="mask0_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={23}
      y={17}
      width={3}
      height={4}
    >
      <path d="M23.085 17.895H25.8143V20.5887H23.085V17.895Z" fill="white" />
    </mask>
    <g mask="url(#mask0_2528_229)">
      <mask
        id="mask1_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={23}
        y={17}
        width={3}
        height={4}
      >
        <path
          d="M24.4641 20.5838C25.1936 20.5838 25.7857 19.9903 25.7857 19.2588C25.7857 18.5267 25.1936 17.9331 24.4641 17.9331C23.7346 17.9331 23.1426 18.5267 23.1426 19.2588C23.1426 19.9903 23.7346 20.5838 24.4641 20.5838Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2528_229)">
        <path
          d="M-0.951172 -0.34021H26.844V21.6794H-0.951172V-0.34021Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask2_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={18}
      y={14}
      width={4}
      height={4}
    >
      <path d="M18.1787 14.6469H21.0954V17.5276H18.1787V14.6469Z" fill="white" />
    </mask>
    <g mask="url(#mask2_2528_229)">
      <mask
        id="mask3_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={18}
        y={14}
        width={4}
        height={4}
      >
        <path
          d="M21.0308 16.0709C21.0308 15.2847 20.3953 14.6475 19.6089 14.6475C18.8226 14.6475 18.1885 15.2847 18.1885 16.0731C18.1885 16.8615 18.8247 17.4972 19.6089 17.4972C20.3932 17.4972 21.0287 16.8593 21.0287 16.0709"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_2528_229)">
        <path
          d="M-0.952148 -0.340576H26.843V21.6791H-0.952148V-0.340576Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask4_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={14}
      y={10}
      width={4}
      height={4}
    >
      <path d="M14.3184 10.0668H17.4062V13.1634H14.3184V10.0668Z" fill="white" />
    </mask>
    <g mask="url(#mask4_2528_229)">
      <mask
        id="mask5_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={14}
        y={10}
        width={4}
        height={4}
      >
        <path
          d="M15.8821 10.1006C15.0431 10.1006 14.3613 10.7835 14.3613 11.6246C14.3613 12.4658 15.0431 13.1494 15.8821 13.1494C16.721 13.1494 17.4021 12.4658 17.4021 11.6246C17.4021 10.7835 16.721 10.1006 15.8821 10.1006Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_2528_229)">
        <path
          d="M-0.952148 -0.340698H26.843V21.6789H-0.952148V-0.340698Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask6_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={10}
      y={5}
      width={4}
      height={4}
    >
      <path d="M10.458 5.50818H13.7471V8.86292H10.458V5.50818Z" fill="white" />
    </mask>
    <g mask="url(#mask6_2528_229)">
      <mask
        id="mask7_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={10}
        y={5}
        width={4}
        height={4}
      >
        <path
          d="M10.4951 7.17266C10.4951 8.06866 11.2204 8.79588 12.1141 8.79588C13.007 8.79588 13.7323 8.06866 13.7323 7.17266C13.7323 6.27665 13.007 5.54944 12.1141 5.54944C11.2204 5.54944 10.4951 6.27454 10.4951 7.17266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask7_2528_229)">
        <path
          d="M-0.952148 -0.34021H26.843V21.6794H-0.952148V-0.34021Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask8_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={5}
      y={2}
      width={5}
      height={4}
    >
      <path d="M5.66602 2.0437H9.17049V5.50816H5.66602V2.0437Z" fill="white" />
    </mask>
    <g mask="url(#mask8_2528_229)">
      <mask
        id="mask9_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={5}
        y={2}
        width={5}
        height={4}
      >
        <path
          d="M9.11221 3.76599C9.11221 2.81583 8.34271 2.04431 7.39294 2.04431C6.44247 2.04431 5.67578 2.81583 5.67578 3.7681C5.67578 4.72107 6.44458 5.48978 7.39505 5.48978C8.34482 5.48978 9.11432 4.71896 9.11221 3.76599Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask9_2528_229)">
        <path
          d="M-0.952148 -0.340576H26.843V21.6791H-0.952148V-0.340576Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask10_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={4}
      height={5}
    >
      <path
        d="M0.0996094 0.690918H3.8524V4.47608H0.0996094V0.690918Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask10_2528_229)">
      <mask
        id="mask11_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={4}
        height={5}
      >
        <path
          d="M3.77557 2.57616C3.77557 1.56833 2.96118 0.753906 1.9581 0.753906C0.955716 0.753906 0.140625 1.57114 0.140625 2.57616C0.140625 3.58188 0.955716 4.39841 1.9581 4.39841C2.96118 4.39841 3.77557 3.58188 3.77557 2.57616Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask11_2528_229)">
        <path
          d="M-0.951172 -0.341064H26.844V21.6786H-0.951172V-0.341064Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask12_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={18}
      y={6}
      width={3}
      height={3}
    >
      <path d="M18.3506 6.28247H20.6668V8.60477H18.3506V6.28247Z" fill="white" />
    </mask>
    <g mask="url(#mask12_2528_229)">
      <mask
        id="mask13_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={18}
        y={6}
        width={3}
        height={3}
      >
        <path
          d="M19.4951 6.33691C18.8743 6.33691 18.3721 6.84118 18.3721 7.4629C18.3721 8.08532 18.8743 8.58888 19.4951 8.58888C20.1152 8.58888 20.6181 8.08532 20.6181 7.4629C20.6181 6.84118 20.1152 6.33691 19.4951 6.33691Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask13_2528_229)">
        <path
          d="M-0.952148 -0.34082H26.843V21.6788H-0.952148V-0.34082Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask14_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={23}
      y={3}
      width={3}
      height={3}
    >
      <path d="M23.085 3.01428H25.599V5.50819H23.085V3.01428Z" fill="white" />
    </mask>
    <g mask="url(#mask14_2528_229)">
      <mask
        id="mask15_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={23}
        y={3}
        width={3}
        height={3}
      >
        <path
          d="M25.5686 4.27453C25.5686 3.59725 25.0201 3.04797 24.3453 3.04797C23.6698 3.04797 23.1219 3.59725 23.124 4.27453C23.1261 4.95111 23.6719 5.50109 24.3474 5.50109C25.0222 5.50109 25.5707 4.95111 25.5707 4.27453"
          fill="white"
        />
      </mask>
      <g mask="url(#mask15_2528_229)">
        <path
          d="M-0.952148 -0.340698H26.843V21.6789H-0.952148V-0.340698Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask16_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={20}
      width={2}
      height={2}
    >
      <path d="M1.11914 20.2352H2.57326V21.6932H1.11914V20.2352Z" fill="white" />
    </mask>
    <g mask="url(#mask16_2528_229)">
      <mask
        id="mask17_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={1}
        y={20}
        width={2}
        height={2}
      >
        <path
          d="M2.57185 20.9646C2.57185 20.5616 2.24638 20.2352 1.84655 20.2352C1.44742 20.2352 1.11914 20.5616 1.11914 20.9646C1.11914 21.3675 1.44462 21.6918 1.84655 21.6918C2.24848 21.6918 2.57185 21.3675 2.57185 20.9646Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask17_2528_229)">
        <path
          d="M1.11914 20.2352H2.57326V21.6932H1.11914V20.2352Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask18_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={6}
      y={18}
      width={3}
      height={3}
    >
      <path d="M6.45703 18.9447H8.10896V20.601H6.45703V18.9447Z" fill="white" />
    </mask>
    <g mask="url(#mask18_2528_229)">
      <mask
        id="mask19_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={6}
        y={18}
        width={3}
        height={3}
      >
        <path
          d="M7.28264 20.6003C7.73929 20.6003 8.10826 20.2303 8.10826 19.7725C8.10826 19.3146 7.73929 18.9447 7.28264 18.9447C6.826 18.9447 6.45703 19.3146 6.45703 19.7725C6.45703 20.2303 6.826 20.6003 7.28264 20.6003Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask19_2528_229)">
        <path d="M6.45703 18.9447H8.10896V20.601H6.45703V18.9447Z" fill="black" />
      </g>
    </g>
    <mask
      id="mask20_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={11}
      y={15}
      width={2}
      height={3}
    >
      <path d="M11.0752 15.4385H12.9256V17.2938H11.0752V15.4385Z" fill="white" />
    </mask>
    <g mask="url(#mask20_2528_229)">
      <mask
        id="mask21_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={11}
        y={15}
        width={2}
        height={3}
      >
        <path
          d="M12.9247 16.3649C12.9247 15.8543 12.5094 15.438 11.998 15.438C11.4874 15.438 11.0742 15.8543 11.0742 16.367C11.0742 16.8791 11.4895 17.2933 12.0001 17.2933C12.5115 17.2933 12.9247 16.8769 12.9247 16.3649Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask21_2528_229)">
        <path d="M11.0742 15.438H12.9247V17.2933H11.0742V15.438Z" fill="black" />
      </g>
    </g>
    <mask
      id="mask22_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={51}
      y={1}
      width={3}
      height={3}
    >
      <path d="M51.5557 1.82764H53.0736V3.30387H51.5557V1.82764Z" fill="white" />
    </mask>
    <g mask="url(#mask22_2528_229)">
      <mask
        id="mask23_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={51}
        y={1}
        width={3}
        height={3}
      >
        <path
          d="M51.6182 2.56856C51.6182 2.97155 51.9436 3.29788 52.3456 3.29788C52.7475 3.29788 53.073 2.97155 53.073 2.56856C53.073 2.16557 52.7475 1.83923 52.3456 1.83923C51.9436 1.83923 51.6182 2.16557 51.6182 2.56856Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask23_2528_229)">
        <path
          d="M27.3154 0.744019H55.1597V23.8728H27.3154V0.744019Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask24_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={46}
      y={2}
      width={2}
      height={3}
    >
      <path d="M46.0703 2.9126H47.7713V4.62935H46.0703V2.9126Z" fill="white" />
    </mask>
    <g mask="url(#mask24_2528_229)">
      <mask
        id="mask25_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={46}
        y={2}
        width={2}
        height={3}
      >
        <path
          d="M46.9096 2.93188C46.4529 2.93188 46.084 3.30182 46.084 3.75967C46.084 4.21752 46.4529 4.58745 46.9096 4.58745C47.3662 4.58745 47.7352 4.21752 47.7352 3.75967C47.7352 3.30182 47.3662 2.93188 46.9096 2.93188Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask25_2528_229)">
        <path
          d="M27.3164 0.743896H55.1607V23.8726H27.3164V0.743896Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask26_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={41}
      y={6}
      width={3}
      height={3}
    >
      <path d="M41.2217 6.18506H43.1752V8.15289H41.2217V6.18506Z" fill="white" />
    </mask>
    <g mask="url(#mask26_2528_229)">
      <mask
        id="mask27_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={41}
        y={6}
        width={3}
        height={3}
      >
        <path
          d="M42.1904 6.23804C41.6791 6.23804 41.2666 6.65369 41.2666 7.16639C41.2666 7.6791 41.6812 8.09264 42.1925 8.09264C42.7039 8.09264 43.1163 7.67699 43.1163 7.16428C43.1163 6.65158 42.7018 6.23804 42.1904 6.23804Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask27_2528_229)">
        <path
          d="M27.3164 0.744263H55.1607V23.873H27.3164V0.744263Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask28_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={33}
      y={14}
      width={3}
      height={4}
    >
      <path d="M33.5342 14.9281H35.835V17.2771H33.5342V14.9281Z" fill="white" />
    </mask>
    <g mask="url(#mask28_2528_229)">
      <mask
        id="mask29_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={33}
        y={14}
        width={3}
        height={4}
      >
        <path
          d="M34.6982 17.1946C35.3183 17.1946 35.8213 16.6911 35.8213 16.0693C35.8213 15.4469 35.3183 14.9434 34.6982 14.9434C34.0774 14.9434 33.5752 15.4469 33.5752 16.0693C33.5752 16.6911 34.0774 17.1946 34.6982 17.1946Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask29_2528_229)">
        <path
          d="M27.3164 0.743652H55.1607V23.8724H27.3164V0.743652Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask30_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={28}
      y={2}
      width={4}
      height={4}
    >
      <path d="M28.3965 2.94421H31.0999V5.62309H28.3965V2.94421Z" fill="white" />
    </mask>
    <g mask="url(#mask30_2528_229)">
      <mask
        id="mask31_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={28}
        y={2}
        width={4}
        height={4}
      >
        <path
          d="M31.0513 4.27302C31.0513 3.54158 30.4593 2.948 29.7297 2.948C29.0002 2.948 28.4082 3.54158 28.4082 4.27513C28.4082 5.00867 29.0002 5.60015 29.7297 5.60015C30.4593 5.60015 31.0513 5.00656 31.0513 4.27513"
          fill="white"
        />
      </mask>
      <g mask="url(#mask31_2528_229)">
        <path
          d="M27.3164 0.744385H55.1607V23.8731H27.3164V0.744385Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask32_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={33}
      y={6}
      width={4}
      height={3}
    >
      <path d="M33.1406 6.00464H36.0138V8.88536H33.1406V6.00464Z" fill="white" />
    </mask>
    <g mask="url(#mask32_2528_229)">
      <mask
        id="mask33_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={33}
        y={6}
        width={4}
        height={3}
      >
        <path
          d="M33.1621 7.4612C33.1621 8.24749 33.7976 8.88538 34.584 8.88538C35.3703 8.88538 36.0037 8.24749 36.0037 7.4612C36.0037 6.67491 35.3682 6.03772 34.584 6.03772C33.7997 6.03772 33.1642 6.67491 33.1642 7.46401"
          fill="white"
        />
      </mask>
      <g mask="url(#mask33_2528_229)">
        <path
          d="M27.3164 0.743774H55.1607V23.8725H27.3164V0.743774Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask34_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={36}
      y={10}
      width={4}
      height={4}
    >
      <path d="M36.7773 10.3256H39.8413V13.4827H36.7773V10.3256Z" fill="white" />
    </mask>
    <g mask="url(#mask34_2528_229)">
      <mask
        id="mask35_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={36}
        y={10}
        width={4}
        height={4}
      >
        <path
          d="M39.8316 11.9074C39.8316 11.0669 39.1497 10.3833 38.3108 10.3833C37.4719 10.3833 36.79 11.0669 36.79 11.9074C36.79 12.7485 37.4719 13.4321 38.3108 13.4321C39.1497 13.4321 39.8316 12.7485 39.8316 11.9074Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask35_2528_229)">
        <path
          d="M27.3164 0.744019H55.1607V23.8728H27.3164V0.744019Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask36_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={40}
      y={14}
      width={4}
      height={5}
    >
      <path d="M40.3818 14.6575H43.7159V18.0003H40.3818V14.6575Z" fill="white" />
    </mask>
    <g mask="url(#mask36_2528_229)">
      <mask
        id="mask37_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={40}
        y={14}
        width={4}
        height={4}
      >
        <path
          d="M42.076 14.7378C41.1823 14.7378 40.457 15.465 40.457 16.361C40.457 17.257 41.1823 17.9835 42.076 17.9835C42.9696 17.9835 43.695 17.257 43.695 16.361C43.695 15.465 42.9696 14.7378 42.076 14.7378Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask37_2528_229)">
        <path
          d="M27.3154 0.744019H55.1597V23.8728H27.3154V0.744019Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask38_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={45}
      y={18}
      width={4}
      height={4}
    >
      <path d="M45.0684 18.0005H48.5827V21.4889H45.0684V18.0005Z" fill="white" />
    </mask>
    <g mask="url(#mask38_2528_229)">
      <mask
        id="mask39_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={45}
        y={18}
        width={4}
        height={4}
      >
        <path
          d="M45.0801 19.7663C45.0801 20.7172 45.8489 21.488 46.7993 21.488C47.7498 21.488 48.5165 20.7172 48.5165 19.7642C48.5165 18.8119 47.7477 18.0432 46.7972 18.0432C45.8468 18.0432 45.0801 18.814 45.0801 19.7663Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask39_2528_229)">
        <path
          d="M27.3164 0.744019H55.1607V23.8728H27.3164V0.744019Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask40_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={50}
      y={19}
      width={5}
      height={4}
    >
      <path d="M50.3848 19.0848H54.0793V22.7891H50.3848V19.0848Z" fill="white" />
    </mask>
    <g mask="url(#mask40_2528_229)">
      <mask
        id="mask41_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={50}
        y={19}
        width={5}
        height={4}
      >
        <path
          d="M54.0536 20.9563C54.0536 19.9505 53.2385 19.1333 52.2354 19.1333C51.2331 19.1333 50.418 19.9505 50.418 20.9563C50.418 21.9613 51.2331 22.7785 52.2354 22.7785C53.2385 22.7785 54.0536 21.9613 54.0536 20.9563Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask41_2528_229)">
        <path
          d="M27.3174 0.743896H55.1617V23.8726H27.3174V0.743896Z"
          fill="black"
        />
      </g>
    </g>
    <mask
      id="mask42_2528_229"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x={28}
      y={18}
      width={4}
      height={3}
    >
      <path d="M28.624 18.0321H31.0693V20.493H28.624V18.0321Z" fill="white" />
    </mask>
    <g mask="url(#mask42_2528_229)">
      <mask
        id="mask43_2528_229"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={28}
        y={18}
        width={4}
        height={3}
      >
        <path
          d="M28.623 19.2591C28.623 19.9364 29.1716 20.4857 29.8464 20.4857C30.5219 20.4857 31.0697 19.9364 31.0676 19.2591C31.0655 18.5826 30.5219 18.0326 29.8464 18.0326C29.1716 18.0326 28.623 18.5826 28.623 19.2591Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask43_2528_229)">
        <path d="M28.623 18.0326H31.0683V20.4843H28.623V18.0326Z" fill="black" />
      </g>
    </g>
  </svg>
  
  );
};

export default GeneseqLogo;
