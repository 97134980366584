"use client";

import React, { ReactNode } from "react";
import Link from "next/link";

interface LocalizedClientLinkProps {
  href: String;
  children?: ReactNode;
  className?: string;
  onClick?: (e) => void;
}
const LocalizedClientLink = ({
  children,
  href,
  className,
  onClick,
}: LocalizedClientLinkProps) => {
  return (
    <Link className={className} href={`${href}`} onClick={onClick}>
      {children}
    </Link>
  );
};

export default LocalizedClientLink;
