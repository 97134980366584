import React from "react";

const LinkedinLogoBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M7.33331 10.5V17.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 13.5V17.5M11.3333 13.5C11.3333 11.8431 12.6764 10.5 14.3333 10.5C15.9902 10.5 17.3333 11.8431 17.3333 13.5V17.5M11.3333 13.5V10.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.34133 7.5H7.33234"
        stroke="black"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.83331 12.5C2.83331 8.02166 2.83331 5.78249 4.22455 4.39124C5.6158 3 7.85497 3 12.3333 3C16.8116 3 19.0508 3 20.4421 4.39124C21.8333 5.78249 21.8333 8.02166 21.8333 12.5C21.8333 16.9783 21.8333 19.2175 20.4421 20.6088C19.0508 22 16.8116 22 12.3333 22C7.85497 22 5.6158 22 4.22455 20.6088C2.83331 19.2175 2.83331 16.9783 2.83331 12.5Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkedinLogoBlack;
