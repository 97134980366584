import constants from "@/lib/constants";
import SocMedButton from ".";
import IgLogo from "@/modules/common/icons/ig-logo";
import LinkedinLogo from "@/modules/common/icons/linkedin-logo";
import LinkedinLogoBlack from "../../icons/linkedin-logo-black";

interface SocMedButtonProp {
  classNames?: string;
  path?: string;
}

const { appConfig } = constants;

const IgButton = ({ classNames }: SocMedButtonProp) => {
  return (
    <SocMedButton path={appConfig.igUrl} classNames={classNames}>
      <IgLogo />
    </SocMedButton>
  );
};
const LinkedInButton = ({ classNames }: SocMedButtonProp) => {
  return (
    <SocMedButton path={appConfig.linkedInUrl} classNames={classNames}>
      <LinkedinLogo />
    </SocMedButton>
  );
};
const LinkedInButtonBlack = ({ classNames, path }: SocMedButtonProp) => {
  return (
    <SocMedButton path={path ?? appConfig.linkedInUrl} classNames={classNames}>
      <LinkedinLogoBlack />
    </SocMedButton>
  );
};

export {
  IgButton,
  LinkedInButton,
  LinkedInButtonBlack,
};
