import React, { ReactNode } from "react";
import Link from "next/link";

interface SocMedButtonProps {
  children: ReactNode;
  path: string;
  classNames: string;
}
const SocMedButton = ({
  children,
  path = "#",
  classNames = "flex items-start justify-center rounded-full ",
}: SocMedButtonProps) => {
  return (
    <button className={classNames}>
      <Link target="_blank" href={path}>{children}</Link>
    </button>
  );
};

export default SocMedButton;
